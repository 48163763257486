import React from "react"
import { graphql } from "gatsby"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { Link } from "gatsby"
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Layout from '../../components/layout'
import { StaticImage } from 'gatsby-plugin-image'
import DetrainAnimation_gif from '../../images/Detrain-Cloud.gif'

const MissionSciencePage = () => {
  return (
    <Layout pageTitle="INCUS Science Goals and Objectives">
      <h1>Science Goals and Objectives</h1>
      <h4>
        The goal of <strong>INCUS</strong> is to understand why, when and where tropical convective storms form, and why only some storms produce extreme weather.
      </h4>

      <p>Convective storms are the source of more than half the precipitation and are the cause of most severe weather on earth. The frequency of extreme weather has increased over the last 40 years, and is expected to continue increasing with warming climates. Despite the fundamental role that convective storms occupy in the atmosphere, our understanding of convective storm processes is limited. While numerical models can help bridge this gap, there are major uncertainties in their representation of convective clouds. At present, the vertical transport of water and air within our most advanced numerical models can differ from case study observations of convective mass flux by more than 200%. Therefore, INCUS is needed to provide the first tropics-wide investigation of the evolution of the vertical transport of air and water by convective storms, which will bridge the gap and enhance our understanding of these important parts of our atmosphere.</p>
      <StaticImage
        alt="A single convective storm photographed from the ISS"
        src="../../images/iss-convective-storm-single-thin.png"
        layout="constrained"
        placeholder="blurred"
      />
      <h2>Convective-scale through global circulations impacted by convective mass flux</h2>
      <p>
        Convective storms transport air and water vertically in the atmosphere and provide mixing in the planetary boundary layer and troposphere and is called Convective Mass Flux (CMF). CMF is important for a variety of convective-scale through large-scale global circulations in our atmosphere. A summary of these impacts are as follows:
      </p>
      <Row>


        <Col md={8} className="align-self-center">
          <h4 className="d-flex justify-content-center">Convective-scale circulations</h4>
          <p>
            The development of deep convection can occur on very short timescales with possible vertical development of strong convective thunderstorms. Deep convection transports water, aerosols, and trace gases from the lower to upper troposphere. Severe and high-impact weather, including tornadoes, hail, and flooding, can result from deep and intense thunderstorms with very strong CMF.
          </p>
        </Col>
        <Col md={4}>
          <StaticImage
            alt="Multiple convective storms with overshooting tops clearly visible photographed from space"
            src="../../images/iss_tstorms_2.jpeg"
            layout="constrained"
            placeholder="blurred"
          />
        </Col>
      </Row>
      <Row>
        <Col md={8} className="align-self-center">
          <h4 className="d-flex justify-content-center">Large-scale global circulations</h4>
          <p>
            In the tropics, preferred regions of convective storm development (e.g., in the tropics along the Intertropical Convergence Zone; ITCZ ) and the vertical transport of energy and water are important in driving the Hadley Cell.
          </p>
        </Col>
        <Col md={4}>
          <StaticImage
            alt="A simplified diagram showing the circulation patterns of the earth"
            src="../../images/simple-circulation-diagram.png"
            layout="constrained"
            placeholder="tracedSVG"
          />

        </Col>
      </Row>
      <p>
        Thus, convective storms play a critical role in the vertical transport of air and water globally, with implications for upper tropospheric moistening, aerosol transport, radiation impacts, high-impact and severe weather, and large-scale global circulations.
      </p>

      <h2>
        INCUS Objectives
      </h2>

      <ol className="objectives-list">
        <Row>
          <Col md={8} className="align-self-center">
            <li>Determine the predominant environmental properties controlling convective mass flux in tropical convective storms.</li>

          </Col>
          <Col md={4}>
            <StaticImage
              alt="A simplified diagram showing the circulation patterns of the earth"
              src="../../images/delta-t-single-cloud.png"
              layout="constrained"
              placeholder="blurred"
            />

          </Col>
        </Row>
        <Row>
          <Col md={8} className="align-self-center">
            <li>Determine the relationship between convective mass flux and high anvil clouds.</li>

          </Col>
          <Col md={4}>
            <StaticImage
              alt="A picture from the international space station showing multiple convective systems with a large anvil encompassing them all"
              src="../../images/iss_tstorms_2.jpeg"
              layout="constrained"
              placeholder="blurred"
            />

          </Col>
        </Row>
        <Row>
          <Col md={8} className="align-self-center">
            <li>Determine the relationship between convective mass flux and the type and intensity of the extreme weather produced.</li>

          </Col>
          <Col md={4}>
            <StaticImage
              alt="A picture of a pickup truck towing an airboat in high flood waters with two rescuers in the airboat with life vests"
              src="../../images/harvey_rescue.jpg"
              layout="constrained"
              placeholder="blurred"
            />

          </Col>
        </Row>
        <Row>
          <Col md={8} className="align-self-center">
            <li>Evaluate these determined relationships between convective mass flux and environmental factors, high anvil clouds, and extreme weather in weather and climate models.</li>

          </Col>
          <Col md={4}>
            <img src={DetrainAnimation_gif} alt="An animation of modeled anvil clouds" className="img-fluid" />
          </Col>
        </Row>


      </ol>

    </Layout >

  )
}

export default MissionSciencePage

